import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Template from 'src/slides/mobile/_templates/chapter_1/sub_2/slide2';

const Slide = () => {
  const query = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "chapter_1/sub_2/3.png" }) {
        childImageSharp {
          fixed(width: 782) {
            ...GatsbyImageSharpFixed_withWebp_noBase64
          }
        }
      }
      animation: mdx(
        frontmatter: {
          language: { eq: "RU" }
          title: { eq: "slide-1-2-1-2" }
        }
      ) {
        body
      }
    }
  `);
  return (
    <Template query={query} />
  );
};

export default Slide;
